import React, { Component } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Media from "./components/media";
import Control from "./components/control";
import Home from "./components/home";
import NotFound from "./components/notFound";
import NavBar from "./components/navBar";
//import auth from "./services/authService";
//import LoginForm from "./components/loginForm";
//import RegisterForm from "./components/registerForm";
//import Logout from "./components/logout";
import "./App.css";

class App extends Component {
  state = {};

  componentDidMount() {
    //const user = auth.getCurrentUser();
    //this.setState({ user });
    document.title = "GranVan";
  }

  render() {
    const { user } = this.state;

    //let bob = "this is a test";
    //console.log(bob);
    //window.alert("this is a test alert");
    return (
      <React.Fragment>
        <NavBar user={user} />
        <Routes>
          {/*<Route path="register" element={<RegisterForm />} />
          <Route path="login" element={<LoginForm />} />
    <Route path="logout" element={<Logout />} />*/}

          <Route path="/" element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="media" element={<Media />} />
          <Route path="control" element={<Control />} />
          <Route path="*" exact={true} element={<NotFound />} />
        </Routes>
      </React.Fragment>
    );
  }
}

export default App;

// return (
//   <main className="container">
//     {/*
//     <h1>GranVan</h1>
//     <ImageGallery items={images} />
//     <DateTimeComponent />
//     <GvTemperatures />
//     */}
//   </main>
// );
