import React, { Component } from "react";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import Temp from "./gvTemp";
import http from "../services/httpService";

class GvTemperatures extends Component {
  state = {
    temperatures: [
      { id: 0, name: "Galley", value: -17.77777, valid: "false" },
      { id: 1, name: "Undercarrage", value: -17.77777, valid: "true" },
      { id: 2, name: "Battery", value: -17.77777, valid: "true" },
      { id: 3, name: "OutsideDvr", value: -17.77777, valid: "true" },
      { id: 3, name: "OutsidePas", value: -17.77777, valid: "true" },
      { id: 4, name: "Bunk", value: -17.77777, valid: "true" },
    ],
  };

  async updateTemperatures() {
    console.log("Updating temperatures");
    const { data: temperatures } = await http.get("/temperatures");
    this.setState({ temperatures });
    console.log(temperatures);
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.updateTemperatures();
    this.interval = setInterval(() => this.updateTemperatures(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  // handleAdd = () => {
  //   const obj = { title: "a", body: "b" };
  // };

  handleIncrement = async () => {
    //this.setState({ value: this.state.value + 1.0 });
    const obj = { id: 3, name: "success", value: -22.9 };
    try {
      const { data: post } = await http.post("/temperatures", obj);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This post has already been deleted.");
    }
  };

  // Optimistic Updater example
  handleDelete = async (post) => {
    const originalPosts = this.state.posts;
    const posts = this.state.posts.filter((p) => p.id !== post.id);
    this.setState({ posts });

    try {
      await http.delete("/" + post.id);
      //throw new Error("");    // Force an error for debugging
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This post has already been deleted.");
      this.setState({ posts: originalPosts });
    }
  };

  render() {
    console.log("in render");
    console.log(this.state.temperatures);
    return (
      <fieldset id="fieldset">
        <legend>Temperatures</legend>
        <table>
          <tbody>
            <tr>
              <td>
                <h6>{this.state.temperatures[0].name}</h6>
              </td>
              <td>
                <span className="badge bg-primary m-1 me-4">
                  {Number(
                    this.state.temperatures[0].value * (9.0 / 5.0) + 32.0
                  ).toFixed(1)}&nbsp;&deg;F
                </span>
              </td>
              <td>
                <h6>{this.state.temperatures[1].name}</h6>
              </td>
              <td>
                <span className="badge bg-primary m-1">
                  {Number(
                    this.state.temperatures[1].value * (9.0 / 5.0) + 32.0
                  ).toFixed(1)}&nbsp;&deg;F
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h6>{this.state.temperatures[2].name}</h6>
              </td>
              <td>
                <span className="badge bg-primary m-1 me-4">
                  {Number(
                    this.state.temperatures[2].value * (9.0 / 5.0) + 32.0
                  ).toFixed(1)}&nbsp;&deg;F
                </span>
              </td>
              <td>
                <h6>{this.state.temperatures[3].name}</h6>
              </td>
              <td>
                <span className="badge bg-primary m-1">
                  {Number(
                    this.state.temperatures[3].value * (9.0 / 5.0) + 32.0
                  ).toFixed(1)}&nbsp;&deg;F
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h6>{this.state.temperatures[5].name}</h6>
              </td>
              <td>
                <span className="badge bg-primary m-1 me-4">
                  {Number(
                    this.state.temperatures[5].value * (9.0 / 5.0) + 32.0
                  ).toFixed(1)}&nbsp;&deg;F
                </span>
              </td>
              <td>
                <h6>{this.state.temperatures[4].name}</h6>
              </td>
              <td>
                <span className="badge bg-primary m-1 me-4">
                  {Number(
                    this.state.temperatures[4].value * (9.0 / 5.0) + 32.0
                  ).toFixed(1)}&nbsp;&deg;F
                </span>
              </td>
            </tr>
            {/*this.state.temperatures.map((temp) => (
              <Temp
                key={temp.id}
                name={temp.name}
                value={Number(temp.value * (9.0 / 5.0) + 32.0).toFixed(2)}
                valid={temp.valid}
              ></Temp>
            ))*/}
          </tbody>
        </table>
        {/*<button
          onClick={this.handleIncrement}
          className="btn btn-primary btn-sm m-1"
        >
          increment
        </button>*/}
      </fieldset>
    );
  }
}

export default GvTemperatures;

//                   value={Math.round(temp.value * (9.0 / 5.0) + 32.0).toFixed(2)}
