import { init, captureMessage } from "@sentry/browser";

// Log into this site to get errors
// https://sentry.io/organizations/petersen-technology/issues/?project=6418634&query=is%3Aunresolved

function initLogger() {
  init({
    dsn: "https://0973d972fcb9482082cc8ff52ef520ab@o1252451.ingest.sentry.io/6418634",
  });
}

function log(error) {
  captureMessage("Logging the error", error);
}

export default {
  initLogger,
  log,
};
