import React, { Component } from "react";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import http from "../services/httpService";
import LineChart from "./LineChart";

class GvMaxxAirGraph extends Component {
  state = {
    maxxAirFan: [
      {
        id: 0,
        name: "MaxxAirFan",
        hoodState: 1,
        fanDirection: 0,
        fanSpeed: 50,
      },
    ],
    userData: {
      labels: ["one", "two", "three", "four", "five"],
      datasets: [
        {
          label: "Users Gained",
          data: [45, 23, 66, 22, 40],
        },
      ],
    },
  };

  async updateMaxxAirData() {
    console.log("Updating MaxxAir history");
    const { data: maxxAirFan } = await http.get("/maxxAirFan/history");
    this.setState({ maxxAirFan });
//     console.log("bob=");
//     console.log(maxxAirFan);
//     const bill = maxxAirFan.map((data) => data.maxxAirFan['fanSpeed']);
//     console.log ("bill = " + bill);
    var userData = {
      labels: maxxAirFan.map((data) => data.time),
      datasets: [
        {
          //label: maxxAirFan[0].maxxAirFan.name,
          label: "fanSpeed",
          data: maxxAirFan.map((data) => data.maxxAirFan.fanSpeed),
          backgroundColor: ["red"],
        },
        {
          label: "fanDirection",
          data: maxxAirFan.map((data) => data.maxxAirFan.fanDirection),
          backgroundColor: ["green"],
        },
        {
          label: "hoodState",
          data: maxxAirFan.map((data) => data.maxxAirFan.hoodState),
          backgroundColor: ["blue"],
        },
      ],
    };
    this.setState({ userData });
    //console.log(userData);
    //console.log(temperatures[0].temperatures[0]);
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.updateMaxxAirData();
    this.interval = setInterval(() => this.updateMaxxAirData(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { results } = this.state;

    return (
      <fieldset id="fieldset">
        <legend>MaxxAir over 24 Hr</legend>
        <LineChart chartData={this.state.userData} />
      </fieldset>
    );
  }
}

export default GvMaxxAirGraph;

//<div style={{ width: 350 }}>
//<LineChart chartData={this.state.userData} />
//</div>
