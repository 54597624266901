import React, { Component } from "react";
import { toast } from "react-toastify";
import http from "../services/httpService";
import "bootstrap/dist/css/bootstrap.min.css";

class dvr_GarageVentControl extends Component {
  state = {
    id: 0,
    name: "dvr_GarageVents",
    dvr_bunkFan: 0,
    dvr_garageFan: 0,
    dvr_garageVent: 0,
    dvr_outsideVent: 0,
    pas_bunkFan: 0,
    pas_garageFan: 0,
    pas_garageVent: 0,
    pas_outsideVent: 0,
  };

  async updateControls() {
    //console.log("Updating controls GarageVentControl");
    const { data: controls } = await http.get("/GarageVents");
    this.setState(controls);
    //console.log(controls);
    //console.log("dvr_garageFan: %d", this.state.dvr_garageFan);
    //console.log("dvr_garageVent: %d", this.state.dvr_garageVent);
    //console.log("dvr_outsideVent: %d", this.state.pas_outsideVent);
    //console.log("pas_garageFan: %d", this.state.pas_garageFan);
    //console.log("pas_garageVent: %d", this.state.pas_garageVent);
    //console.log("pas_outsideVent: %d", this.state.pas_outsideVent);
  }

  componentDidMount() {
    console.log("componentDidMount GarageVentControl");
    this.updateControls();
    this.interval = setInterval(() => this.updateControls(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // Optimistic Updater
  handleButtonPress = async (val) => {
    const originalState = this.state; // Save an original copy if things go bad

    const obj = {
      id: 0,
      name: "GarageVents",
      dvr_bunkFan: 0,
      dvr_garageFan: 0,
      dvr_garageVent: 0,
      dvr_outsideVent: 0,
      pas_bunkFan: 0,
      pas_garageFan: 0,
      pas_garageVent: 0,
      pas_outsideVent: 0,
    };
    obj.dvr_garageFan = this.state.dvr_garageFan;
    obj.dvr_garageVent = this.state.dvr_garageVent;
    obj.dvr_outsideVent = this.state.dvr_outsideVent;
    obj.pas_garageFan = this.state.pas_garageFan;
    obj.pas_garageVent = this.state.pas_garageVent;
    obj.pas_outsideVent = this.state.pas_outsideVent;
    switch (val) {
      case "dvr_BunkFan":
        if (this.state.dvr_bunkFan === 1) {
          obj.dvr_bunkFan = 0;
        } else {
          obj.dvr_bunkFan = 1;
        }
        break;
      case "dvr_VentFan":
        if (this.state.dvr_garageFan === 1) {
          obj.dvr_garageFan = 0;
        } else {
          obj.dvr_garageFan = 1;
        }
        break;
      case "dvr_GarageVent":
        if (this.state.dvr_garageVent === 1) {
          obj.dvr_garageVent = 0;
        } else {
          obj.dvr_garageVent = 1;
        }
        break;
      case "dvr_OutsideVent":
        if (this.state.dvr_outsideVent === 1) {
          obj.dvr_outsideVent = 0;
        } else {
          obj.dvr_outsideVent = 1;
        }
        break;
      case "pas_BunkFan":
        if (this.state.pas_bunkFan === 1) {
          obj.pas_bunkFan = 0;
        } else {
          obj.pas_bunkFan = 1;
        }
        break;
      case "pas_VentFan":
        if (this.state.pas_garageFan === 1) {
          obj.pas_garageFan = 0;
        } else {
          obj.pas_garageFan = 1;
        }
        break;
      case "pas_GarageVent":
        if (this.state.pas_garageVent === 1) {
          obj.pas_garageVent = 0;
        } else {
          obj.pas_garageVent = 1;
        }
        break;
      case "pas_OutsideVent":
        if (this.state.pas_outsideVent === 1) {
          obj.pas_outsideVent = 0;
        } else {
          obj.pas_outsideVent = 1;
        }
        break;
    }

    this.setState(obj); // Go ahead and update the state now and undo later if things go bad

    try {
      const { data: post } = await http.post("/command/GarageVents", obj);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This command was unsuccessful.");
      this.setState(originalState); // Reset our state because things went bad
    }
    //console.log(obj);
  };

  render() {
    return (
      <React.Fragment>
        <fieldset id="fieldset">
          <legend>Garage Vent Control</legend>
          <div class="container">
            {/*
            <div class="row align-items-center">
              <div class="col-4">Bunk Fan</div>
              <div class="col-auto">
                <button
                  onClick={() => this.handleButtonPress("dvr_BunkFan")}
                  className={this.getBadge("dvr_BunkFan")}
                >
                  {this.getBtnText("dvr_BunkFan")}
                </button> 
              </div>
            </div>
    */}
            <div class="row align-items-center">
              <div class="col-4"></div>
              <div class="col-3">Driver</div>
              <div class="col align-text-center">Passenger</div>
            </div>
            <div class="row align-items-center">
              <div class="col-4">Vent Fan</div>
              <div class="col-auto">
                <button
                  onClick={() => this.handleButtonPress("dvr_VentFan")}
                  className={this.getBadge("dvr_VentFan")}
                >
                  {this.getBtnText("dvr_VentFan")}
                </button>
              </div>
              <div class="col-auto">
                <button
                  onClick={() => this.handleButtonPress("pas_VentFan")}
                  className={this.getBadge("pas_VentFan")}
                >
                  {this.getBtnText("pas_VentFan")}
                </button>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-4">Outside Vent</div>
              <div class="col-auto">
                <button
                  onClick={() => this.handleButtonPress("dvr_OutsideVent")}
                  className={this.getBadge("dvr_OutsideVent")}
                >
                  {this.getBtnText("dvr_OutsideVent")}
                </button>
              </div>
              <div class="col-auto">
                <button
                  onClick={() => this.handleButtonPress("pas_OutsideVent")}
                  className={this.getBadge("pas_OutsideVent")}
                >
                  {this.getBtnText("pas_OutsideVent")}
                </button>
              </div>
            </div>
            <div class="row align-items-center">
              <div class="col-4">Garage Vent</div>
              <div class="col-auto">
                <button
                  onClick={() => this.handleButtonPress("dvr_GarageVent")}
                  className={this.getBadge("dvr_GarageVent")}
                >
                  {this.getBtnText("dvr_GarageVent")}
                </button>
              </div>
              <div class="col-auto">
                <button
                  onClick={() => this.handleButtonPress("pas_GarageVent")}
                  className={this.getBadge("pas_GarageVent")}
                >
                  {this.getBtnText("pas_GarageVent")}
                </button>
              </div>
            </div>
          </div>
          <h6 align="right">{this.getStatusString()}</h6>
        </fieldset>
      </React.Fragment>
    );
  }

  getBadge(btn) {
    let classes = "";
    switch (btn) {
      case "dvr_BunkFan":
        classes +=
          this.state.dvr_bunkFan === 0
            ? "btn btn-outline-success btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
      case "dvr_VentFan":
        classes +=
          this.state.dvr_garageFan === 0
            ? "btn btn-outline-success btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
      case "dvr_GarageVent":
        classes +=
          this.state.dvr_garageVent === 0
            ? "btn btn-outline-primary btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
      case "dvr_OutsideVent":
        classes +=
          this.state.dvr_outsideVent === 0
            ? "btn btn-outline-primary btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
      case "pas_BunkFan":
        classes +=
          this.state.pas_bunkFan === 0
            ? "btn btn-outline-success btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
      case "pas_VentFan":
        classes +=
          this.state.pas_garageFan === 0
            ? "btn btn-outline-success btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
      case "pas_GarageVent":
        classes +=
          this.state.pas_garageVent === 0
            ? "btn btn-outline-primary btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
      case "pas_OutsideVent":
        classes +=
          this.state.pas_outsideVent === 0
            ? "btn btn-outline-primary btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
    }
    return classes;
  }

  getBtnText(btn) {
    let text = "";
    let speed = 0;
    switch (btn) {
      case "dvr_BunkFan":
        text += this.state.dvr_bunkFan === 1 ? "Off" : "On";
        break;
      case "dvr_VentFan":
        text += this.state.dvr_garageFan === 1 ? "Off" : "On";
        break;
      case "dvr_GarageVent":
        text += this.state.dvr_garageVent === 1 ? "Close" : "Open";
        break;
      case "dvr_OutsideVent":
        text += this.state.dvr_outsideVent === 1 ? "Close" : "Open";
        break;
      case "pas_BunkFan":
        text += this.state.pas_bunkFan === 1 ? "Off" : "On";
        break;
      case "pas_VentFan":
        text += this.state.pas_garageFan === 1 ? "Off" : "On";
        break;
      case "pas_GarageVent":
        text += this.state.pas_garageVent === 1 ? "Close" : "Open";
        break;
      case "pas_OutsideVent":
        text += this.state.pas_outsideVent === 1 ? "Close" : "Open";
        break;
    }
    return text;
  }

  getStatusString() {
    let status = "DVR[";
    //status += this.state.dvr_bunkFan === 1 ? "On, " : "Off, ";
    status += this.state.dvr_garageFan === 1 ? "On, " : "Off, ";
    status += this.state.dvr_outsideVent === 1 ? "Open, " : "Closed, ";
    status += this.state.dvr_garageVent === 1 ? "Open" : "Closed";
    status += "], PAS[";
    //status += this.state.pas_bunkFan === 1 ? "On, " : "Off, ";
    status += this.state.pas_garageFan === 1 ? "On, " : "Off, ";
    status += this.state.pas_outsideVent === 1 ? "Open, " : "Closed, ";
    status += this.state.pas_garageVent === 1 ? "Open" : "Closed";
    status += "]";
    return status;
  }
}

export default dvr_GarageVentControl;
