import div, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
//import React, { useState, useEffect } from 'react';

class Temp extends Component {
  state = {
    key: this.props.key,
    name: this.props.name,
    value: this.props.value,
    valid: this.props.valid,
  };

  /* Need to refactor to functional component or play some senagians to make useEffect work in class  
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);  // empty dependency array = only called on mount and unmount
*/
  render() {
    return (
      <tr>
        <td>
          <h6>{this.state.name}</h6>
        </td>
        <td>
          <span className="badge bg-primary m-1">{this.formatTemp()}</span>
        </td>
        <td>
          <span className="badge bg-primary m-1">{this.formatValid()}</span>
        </td>
      </tr>
    );
  }

  formatTemp() {
    const { value, valid } = this.state;
    console.log("I am here " + value + " valid=" + valid);
    return value;
    //return valid == "false" ? "-.--" : value;
  }

  formatValid() {
    const { value, valid } = this.state;
    //console.log("I am here " + value + " valid=" + valid);
    return valid == "false" ? 0 : 1;
    //return valid == "false" ? "-.--" : value;
  }
}

export default Temp;
