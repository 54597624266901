import React, { Component } from "react";
import { toast } from "react-toastify";
import http from "../services/httpService";
import "bootstrap/dist/css/bootstrap.min.css";

class KitchenLightsControl extends Component {
  async componentDidMount() {
    // const { data: temperatures } = await http.get("/temperatures");
    // this.setState({ temperatures });
    //console.log(temperatures);
    // TODO: Get state of light and hightlight appropriate button
  }

  handleButtonPress = async (val) => {
    const obj = { name: "KitchenLt", W: 0, B: 0, G: 0, R: 0, Mask: 255 };
    switch (val) {
      case "R":
        obj.W = 0;
        obj.B = 0;
        obj.G = 0;
        obj.R = 255;
        break;
      case "G":
        obj.W = 0;
        obj.B = 0;
        obj.G = 255;
        obj.R = 0;
        break;
      case "B":
        obj.W = 0;
        obj.B = 255;
        obj.G = 0;
        obj.R = 0;
        break;
      case "W":
        obj.W = 1;
        obj.B = 0;
        obj.G = 0;
        obj.R = 0;
        break;
      case "O":
        obj.W = 0;
        obj.B = 0;
        obj.G = 0;
        obj.R = 0;
        break;
    }
    try {
      const { data: post } = await http.post("/command/lights", obj);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This command was unsuccessful.");
    }
    //console.log(obj);
  };

  render() {
    return (
      <React.Fragment>
        <fieldset id="fieldset">
          <legend>Kitchen Lights</legend>

          <button
            onClick={() => this.handleButtonPress("R")}
            className="btn btn-danger btn-sm m-2"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </button>
          <button
            onClick={() => this.handleButtonPress("G")}
            className="btn btn-success btn-sm m-2"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </button>
          <button
            onClick={() => this.handleButtonPress("B")}
            className="btn btn-primary btn-sm m-2"
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </button>
          <button
            onClick={() => this.handleButtonPress("W")}
            className="btn btn-light btn-sm m-2"
          >
            Party
          </button>
          <button
            onClick={() => this.handleButtonPress("O")}
            className="btn btn-dark btn-sm m-2"
          >
            OFF
          </button>
        </fieldset>
      </React.Fragment>
    );
  }
}

export default KitchenLightsControl;

// render() {
//   return (
//     <React.Fragment>
//       <div id="fieldset2">
//         <fieldset>
//           <legend>Front Drivers Side Task light</legend>

//           <button
//             onClick={() => this.handleButtonPress("R")}
//             className="btn btn-danger btn-sm m-2"
//           >
//             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//           </button>
//           <button
//             onClick={() => this.handleButtonPress("G")}
//             className="btn btn-success btn-sm m-2"
//           >
//             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//           </button>
//           <button
//             onClick={() => this.handleButtonPress("B")}
//             className="btn btn-primary btn-sm m-2"
//           >
//             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//           </button>
//           <button
//             onClick={() => this.handleButtonPress("W")}
//             className="btn btn-light btn-sm m-2"
//           >
//             &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
//           </button>
//           <button
//             onClick={() => this.handleButtonPress("O")}
//             className="btn btn-dark btn-sm m-2"
//           >
//             OFF
//           </button>
//         </fieldset>
//       </div>
//     </React.Fragment>
//   );
// }
// }
