import React from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: "https://granvan.io/images/2022/20220116_095011.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220116_095011.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220116_111607.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220116_111607.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220123_090831.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220123_090831.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220129_085409.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220129_085409.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220129_102156.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220129_102156.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220220_092812.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220220_092812.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220226_113740.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220226_113740.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220226_140217.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220226_140217.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220227_092817.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220227_092817.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220227_105036.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220227_105036.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220305_081825.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220305_081825.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220305_085200.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220305_085200.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220305_132820.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220305_132820.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220306_091531.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220306_091531.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220312_164132.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220312_164132.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220313_105336.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220313_105336.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220313_111059.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220313_111059.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220313_111659.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220313_111659.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220313_130451.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220313_130451.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220314_084700.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220314_084700.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220314_101011.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220314_101011.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220314_173826.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220314_173826.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220315_105256.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220315_105256.jpg",
  },
  {
    original: "https://granvan.io/images/2022/Snapchat-1872415738.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_Snapchat-1872415738.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220115_165622.jpg",
    thumbnail:
      "https://granvan.io/images/2022/thumbnails/tn_20220115_165622.jpg",
  },
  {
    original: "https://granvan.io/images/2022/20220105_180605.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220105_190504.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220107_113429.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220107_115010.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220123_083449.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220123_083544.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220123_084043.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220123_085255.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220123_114941.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220128_114228.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220128_124127.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220128_124443.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220128_132053.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220129_084820.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220130_080834.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220209_065218.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220226_075927.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220313_075147.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220315_075416.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220326_103554.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220402_122036.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220410_190621.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220412_063738.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220415_082638.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220415_103622.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220415_130304.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/20220415_143340.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/Snapchat-550015472.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/Snapchat-789092928.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2022/Snapchat-1831629220.jpg",
    thumbnail: "",
  },
];

const Photos2022 = () => {
  return (
    <React.Fragment>
      <ImageGallery items={images} />
    </React.Fragment>
  );
};

export default Photos2022;
