import React from "react";

const Home = () => {
  return (
    <React.Fragment>
      <h1>
        <center>GranVan</center>
      </h1>
      <h3>
        <center>by</center>
        <center>Lisa and Andy Petersen</center>
      </h3>
      <center>
        <img
          class="fit-picture"
          src="https://granvan.io/images/2022/20220412_064532.jpg"
          alt="picture"
        ></img>
      </center>
    </React.Fragment>
  );
};

export default Home;
