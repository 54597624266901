import React, { Component } from "react";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import http from "../services/httpService";
import ScatterChart from "./scatterChart";
import moment from "moment";

class GvTemperatureGraphXY extends Component {
  state = {
    temperatures: [
      { id: 0, name: "Galley", value: -17.77777, valid: "false" },
      { id: 1, name: "Undercarrage", value: -17.77777, valid: "true" },
    ],
    userData: {
      labels: ["one", "two", "three", "four", "five"],
      datasets: [
        {
          label: "Users Gained",
          data: [45, 23, 66, 22, 40],
        },
      ],
    },
  };

  // dataChangePoints: [
  //   { id: 0, name: "Galley Temperature", data: [{time: 10, value: -17.77777, valid: "false"}] },
  //   { id: 1, name: "Undercarrage Temperature", time: 10, value: -17.77777, valid: "true" },
  // ],

// https://react-chartjs-2.js.org/examples/line-chart

  async updateDataChangePoints() {
    console.log("Updating Data Change Points History");
    const { data: dataChangePoints } = await http.get("/temperatures/history");
    this.setState({ dataChangePoints });
    console.log("Starting in Scatter");
    var userData = {
      datasets: [
        {
          //label: dataChangePoints[0].name,
          //data: dataChangePoints.map((data) => data.dataChangePoints[0].data )
          //data: [data: dataChangePoints[0].
          //     { x: 1, y: 2 },
          //     { x: 2, y: 4 },
          //     { x: 3, y: 8 },
          //     { x: 4, y: 16 },
          //   ],
          //   showLine: true,
          //   fill: false,
          //   borderColor: "rgba(0, 200, 0, 1)",
          //   backgroundColor: ["red"];
          // },
          // {
             label: "Chart 2",
             data: [
               { x: "2020-02-15 18:37:39", y: 3 },
               { x: "2020-02-15 18:38:39", y: 4 },
               { x: "2020-02-15 18:39:39", y: 6 },
               { x: "2020-02-15 18:40:39", y: 9 },
             ],
    
             options: {
              scales: {
                title: {
                  display: true,
                  text: "crap",
                },
                xAxes: {
                  type : 'time',
                  distribution: 'linear',
                 },
    
              },
             },
             showLine: true,
             fill: false,
             borderColor: "rgba(200, 0, 0, 1)",
        },
      ],
    };
    this.setState({ userData });
    //console.log(userData);
    //console.log(temperatures[0].temperatures[0]);
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.updateDataChangePoints();
    this.interval = setInterval(() => this.updateDataChangePoints(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { results } = this.state;

    return (
      <fieldset id="fieldset">
        <legend>Temperatures over 24 Hr</legend>
        <ScatterChart chartData={this.state.userData} />
      </fieldset>
    );
  }
}

export default GvTemperatureGraphXY;

//<div style={{ width: 350 }}>
//<LineChart chartData={this.state.userData} />
//</div>

