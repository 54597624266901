import React, { Component } from "react";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import http from "../services/httpService";
import LineChart from "./LineChart";


class GvTemperatureGraph extends Component {
  state = {
    temperatures: [
      { id: 0, name: "Galley", value: -17.77777, valid: "false" },
      { id: 1, name: "Undercarrage", value: -17.77777, valid: "true" },
    ],
    maxxAirFan: [
      {
        id: 0,
        name: "MaxxAirFan",
        hoodState: 1,
        fanDirection: 0,
        fanSpeed: 50,
      },
    ],
    userData: {
      labels: ["one", "two", "three", "four", "five"],
      datasets: [
        {
          label: "Users Gained",
          data: [45, 23, 66, 22, 40],
        },
      ],
    },
  };

  async updateTemperatures() {
    console.log("Updating temperatures history");
    const { data: temperatures } = await http.get("/temperatures/history");
    this.setState({ temperatures });
    var userData = {
      labels: temperatures.map((data) => data.time),
      datasets: [
        {
          label: temperatures[0].temperatures[0].name,
          data: temperatures.map(
            (data) => data.temperatures[0].value * (9.0 / 5.0) + 32.0
          ),
          backgroundColor: ["red"],
        },
        // {
        //   label: temperatures[0].temperatures[1].name + " HT",
        //   data: temperatures.map((data) => data.temperatures[1].heatTape),
        //   backgroundColor: ["green"],
        // },
        {
          label: temperatures[0].temperatures[1].name,
          data: temperatures.map(
            (data) => data.temperatures[1].value * (9.0 / 5.0) + 32.0
          ),
          backgroundColor: ["blue"],
        },
        {
          label: temperatures[0].temperatures[2].name,
          data: temperatures.map(
            (data) => data.temperatures[2].value * (9.0 / 5.0) + 32.0
          ),
          backgroundColor: ["green"],
        },
        {
          label: temperatures[0].temperatures[3].name,
          data: temperatures.map(
            (data) => data.temperatures[3].value * (9.0 / 5.0) + 32.0
          ),
          backgroundColor: ["cyan"],
        },
        {
          label: temperatures[0].temperatures[4].name,
          data: temperatures.map(
            (data) => data.temperatures[4].value * (9.0 / 5.0) + 32.0
          ),
          backgroundColor: ["purple"],
        },
        {
          label: temperatures[0].temperatures[5].name,
          data: temperatures.map(
            (data) => data.temperatures[5].value * (9.0 / 5.0) + 32.0
          ),
          backgroundColor: ["yellow"],
        },
      ],
    };
    this.setState({ userData });
    //console.log(userData);
    //console.log(temperatures[0].temperatures[0]);
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.updateTemperatures();
    this.interval = setInterval(() => this.updateTemperatures(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { results } = this.state;

    return (
      <fieldset id="fieldset">
        <legend>Temperatures over 24 Hr</legend>
        <LineChart chartData={this.state.userData} />
      </fieldset>
    );
  }
}

export default GvTemperatureGraph;

//<div style={{ width: 350 }}>
//<LineChart chartData={this.state.userData} />
//</div>
