import React, { Component } from "react";

class DateTimeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { currentDateTime: Date().toLocaleString() };
  }
  componentDidMount() {
    console.log("TimeComponent Mounted ...");
    this.interval = setInterval(
      () => this.setState({ currentDateTime: Date().toLocaleString() }),
      1000
    );
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <p>{this.state.currentDateTime}</p>
      </div>
    );
  }
}

export default DateTimeComponent;
