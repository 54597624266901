import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: "https://granvan.io/images/2020/14835.jpeg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/14896.jpeg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20200822_164737 1.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20200815_085517.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20200815_084150.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20200726_180641.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20200724_123603.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20201025_151435_030_saved.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20201027_125717_023_saved.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20210108_115137.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2020/20201224_091405.jpg",
    thumbnail: "",
  },
];

const Photos2020 = () => {
  return (
    <React.Fragment>
      <ImageGallery items={images} />
    </React.Fragment>
  );
};

export default Photos2020;
