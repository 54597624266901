import React from "react";
import { Link, NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const NavBar = ({ user }) => {
  return (
    <nav className="navbar navbar-expand navbar-light bg-light">
      {/*}
      <a className="navbar-brand" to="/">
        GranVan
  </a>*/}
      {/*} <a class="navbar-brand" href="/">
        <img
          src="https://granvan.io/favicon.ico"
          width="30"
          height="30"
          alt=""
        ></img>
      </a> */}
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div className="navbar-nav">
          <NavLink className="nav-item nav-link" to="/home">
            Home
          </NavLink>
          <NavLink className="nav-item nav-link" to="/media">
            Media
          </NavLink>
          <NavLink className="nav-item nav-link" to="/control">
            Control
          </NavLink>
          {!user && (
            <React.Fragment>
              <NavLink className="nav-item nav-link" to="/login">
                Login
              </NavLink>
              <NavLink className="nav-item nav-link" to="/register">
                Register
              </NavLink>
            </React.Fragment>
          )}
          {user && (
            <React.Fragment>
              <NavLink className="nav-item nav-link" to="/profile">
                {user.name}
              </NavLink>
              <NavLink className="nav-item nav-link" to="/logout">
                Logout
              </NavLink>
            </React.Fragment>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;

// const NavBar = () => {
//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light">
//       <div className="container-fluid">
//         <Link className="navbar-brand" to="/">
//           GranVan
//         </Link>
//         <button
//           className="navbar-toggler"
//           type="button"
//           data-bs-toggle="collapse"
//           data-bs-target="#navbarNav"
//           aria-controls="navbarNav"
//           aria-expanded="false"
//           aria-label="Toggle navigation"
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div className="collapse navbar-collapse" id="navbarNav">
//           <NavLink className="nav-item nav-link" to="/home">
//             Home
//           </NavLink>
//           <NavLink className="nav-item nav-link" to="/media">
//             Media
//           </NavLink>
//           <NavLink className="nav-item nav-link" to="/control">
//             Control
//           </NavLink>
//         </div>
//       </div>
//     </nav>
//   );
// };

// const NavBar = () => {
//   return (
//     <nav className="navbar navbar-expand-lg navbar-light bg-light">
//       <a className="navbar-brand" to="/">
//         GranVan
//       </a>
//       <button
//         className="navbar-toggler"
//         type="button"
//         data-toggle="collapse"
//         data-target="#navbarNavAltMarkup"
//         aria-controls="navbarNavAltMarkup"
//         aria-expanded="false"
//         aria-label="Toggle navigation"
//       >
//         <span className="navbar-toggler-icon"></span>
//       </button>
//       <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
//         <div className="navbar-nav">
//           <NavLink className="nav-item nav-link" to="/home">
//             Home
//           </NavLink>
//           <NavLink className="nav-item nav-link" to="/media">
//             Media
//           </NavLink>
//           <NavLink className="nav-item nav-link" to="/control">
//             Control
//           </NavLink>
//         </div>
//       </div>
//     </nav>
//   );
// };
