import React, { Component } from "react";
import { toast } from "react-toastify";
import http from "../services/httpService";
import "bootstrap/dist/css/bootstrap.min.css";

class FanControl extends Component {
  state = {
    id: 0,
    name: "MaxxAirFan",
    hoodState: 0,
    fanDirection: 0,
    fanSpeed: 0,
  };

  async updateControls() {
    //console.log("Updating controls maxxAirFan");
    const { data: controls } = await http.get("/maxxAirFan");
    this.setState(controls);
    //console.log(controls);
    console.log("hoodState: %d", this.state.hoodState);
    console.log("fanDirection: %d", this.state.fanDirection);
    console.log("fanSpeed: %d", this.state.fanSpeed);
  }

  componentDidMount() {
    console.log("componentDidMount maxxAirFan");
    this.updateControls();
    this.interval = setInterval(() => this.updateControls(), 60000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // Optimistic Updater
  handleButtonPress = async (val) => {
    const originalState = this.state; // Save an original copy if things go bad

    const obj = {
      id: 0,
      name: "MaxxAirFan",
      hoodState: 0,
      fanDirection: 0,
      fanSpeed: 0,
    };
    obj.hoodState = this.state.hoodState;
    obj.fanDirection = this.state.fanDirection;
    obj.fanSpeed = this.state.fanSpeed;
    switch (val) {
      case "OpenClose":
        if (this.state.hoodState === 1) {
          obj.hoodState = 0;
          obj.fanSpeed = 0;
          obj.fanDirection = 0;
        } else {
          obj.hoodState = 1;
          obj.fanSpeed = 0;
          obj.fanDirection = 0;
        }
        break;
      case "Fan +":
        if (this.state.fanSpeed < 100) obj.fanSpeed = this.state.fanSpeed += 10;
        if (this.state.hoodState === 0) obj.hoodState = 1; // Open the vent if it is closed
        break;
      case "Fan -":
        if (this.state.fanSpeed > 0) obj.fanSpeed = this.state.fanSpeed -= 10;
        break;
      case "InOut":
        if (this.state.fanDirection === 1) {
          obj.fanDirection = 0;
        } else {
          obj.fanDirection = 1;
        }
        break;
    }

    this.setState(obj); // Go ahead and update the state now and undo later if things go bad

    try {
      const { data: post } = await http.post("/command/maxxAirFan", obj);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This command was unsuccessful.");
      this.setState(originalState); // Reset our state because things went bad
    }
    //console.log(obj);
  };

  render() {
    return (
      <React.Fragment>
        <fieldset id="fieldset">
          <legend>MaxxAir Fan Control</legend>

          <button
            onClick={() => this.handleButtonPress("OpenClose")}
            className={this.getBadge("OpenClose")}
          >
            {this.getBtnText("OpenClose")}
          </button>
          <button
            onClick={() => this.handleButtonPress("Fan +")}
            className={this.getBadge("Fan +")}
          >
            {this.getBtnText("Fan +")}
          </button>
          <button
            onClick={() => this.handleButtonPress("Fan -")}
            className={this.getBadge("Fan -")}
          >
            {this.getBtnText("Fan -")}
          </button>
          <button
            onClick={() => this.handleButtonPress("InOut")}
            className={this.getBadge("InOut")}
          >
            {this.getBtnText("InOut")}
          </button>
          <h6 align="right">{this.getStatusString()}</h6>
        </fieldset>
      </React.Fragment>
    );
  }

  getBadge(btn) {
    let classes = "";
    switch (btn) {
      case "OpenClose":
        classes +=
          this.state.hoodState === 0
            ? "btn btn-outline-success btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
      case "Fan +":
        classes += "btn btn-outline-primary btn-sm m-2 btnWidth";
        break;
      case "Fan -":
        classes += "btn btn-outline-primary btn-sm m-2 btnWidth";
        break;
      case "InOut":
        classes +=
          this.state.fanDirection === 0
            ? "btn btn-outline-success btn-sm m-2 btnWidth"
            : "btn btn-outline-dark btn-sm m-2 btnWidth";
        break;
    }
    return classes;
  }

  getBtnText(btn) {
    let text = "";
    let speed = 0;
    switch (btn) {
      case "OpenClose":
        text += this.state.hoodState === 1 ? "Close" : "Open";
        break;
      case "Fan +":
        this.state.fanSpeed < 100
          ? (speed = this.state.fanSpeed + 10)
          : (speed = 100);
        text += speed + " %";
        break;
      case "Fan -":
        this.state.fanSpeed > 0
          ? (speed = this.state.fanSpeed - 10)
          : (speed = 0);
        text += speed + " %";
        break;
      case "InOut":
        text += this.state.fanDirection === 1 ? "Out" : "In";
        break;
    }
    return text;
  }

  getStatusString() {
    let status = "";
    status += this.state.hoodState === 1 ? "Open, " : "Closed, ";
    status += this.state.fanSpeed;
    status += "%, ";
    status += this.state.fanDirection === 1 ? "In" : "Out";
    return status;
  }
}

export default FanControl;
