import React, { Component } from "react";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: "https://granvan.io/images/2021/Snapchat-419492625.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/Snapchat-449582180.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/Snapchat-1640178071.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/Snapchat-1850814338.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/Snapchat-105812282.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/Snapchat-143516067 1.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/Snapchat-1330083527.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/Snapchat-1427784627.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210307_095242_020_saved.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210528_205443.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210526_145941.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210526_075828.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210525_174627.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210523_121129.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210522_141853.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210522_103047.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210514_193547.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210514_165130.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210514_154403.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210514_145931.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210508_130053.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210508_123127.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210312_183259.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210215_095827.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210129_184846.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210116_114646.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210116_111624.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210116_085443.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/Resized_20210215_091203.jpeg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210116_082207.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210113_141808.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210109_073048.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210108_115137.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210109_133132.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210109_162425.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210116_112937.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210125_165839.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210208_180251.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210214_073337.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210214_080213.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210214_082057.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210214_082443.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210214_084637.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210214_090452.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210214_102159.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210214_121436.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210215_074230.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210227_133959.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210302_120345.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210313_143045.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210324_181811.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210507_164735.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210509_135034.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210513_183630.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210513_190212.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210301_184603.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210525_190425.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210718_092502.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210718_151439.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210918_073541.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210918_120909.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20210925_120822.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211023_162816.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211024_115459.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211030_103516.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211030_112353_001_saved.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211031_170557.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211106_130426.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211120_095057.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211205_100856.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211219_111702.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211230_093606.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211230_093645.jpg",
    thumbnail: "",
  },
  {
    original: "https://granvan.io/images/2021/20211230_093706.jpg",
    thumbnail: "",
  },
  {
    original:
      "https://granvan.io/images/2021/Screenshot_20210524-192813_Mountain Project.jpg",
    thumbnail: "",
  },
];

const Photos2021 = () => {
  return (
    <React.Fragment>
      <ImageGallery items={images} />
    </React.Fragment>
  );
};

export default Photos2021;
