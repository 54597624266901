import React from "react";
import Counter from "./counter";
import DateTimeComponent from "./apDateTime";
import GvTemperatures from "./gvTemps";
import LightsControl from "./lightControl";
import KitchenLightsControl from "./kitchenLightControl";
import FanControl from "./fanControl";
import GvTemperatureGraph from "./gvTempGraphs";
import GvMaxxAirGraph from "./gvMaxxAirGraph";
import GvTemperatureGraphXY from "./gvTempGraphsXY";
import GarageVentControl  from "./gvGarageVentControl";

const Control = () => {
  return (
    <React.Fragment>
      <h1 className="header1">GranVan Control</h1>
      <div className="control">
        {/*<DateTimeComponent />*/}
        <GvTemperatures />
        <GvTemperatureGraph />
        {/*<GvMaxxAirGraph />*/}
        {/*<GvTemperatureGraphXY />*/}
        <LightsControl />
        <KitchenLightsControl />
        <FanControl />
        <GarageVentControl />        
      </div>
    </React.Fragment>
  );
};

export default Control;
<h1>Control</h1>;
