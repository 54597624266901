import React from "react";
import Tabs from "./tabs";
import Photos2020 from "./media/photos2020";
import Photos2021 from "./media/photos2021";
import Photos2022 from "./media/photos2022";
import VideoPlayer from "./media/videos";

const data = [
  {
    heading: "2020",
    body: <Photos2020 />,
  },
  {
    heading: "2021",
    body: <Photos2021 />,
  },
  {
    heading: "2022",
    body: <Photos2022 />,
  },
  {
    heading: "Videos",
    body: <VideoPlayer />,
  },
];

const Media = () => {
  return (
    <React.Fragment>
      {/*<h1>
        <center>GranVan Photo Library</center>
      </h1> */}
      <div className="App">
        <Tabs data={data} />
      </div>{" "}
      <h5 className="emphasized">
        <center>(More to come)</center>
      </h5>
    </React.Fragment>
  );
};

export default Media;
