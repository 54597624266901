import React from "react";
//import videojs from "video.js";

class VideoPlayer extends React.Component {
  // componentDidMount() {
  //   this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
  //     console.log("Video.js Ready", this);
  //   });
  // }
  // componentWillUnmount() {
  //   if (this.player) {
  //     this.player.dispose();
  //   }
  // }
  render() {
    return (
      <div>
        <link
          href="https://unpkg.com/video.js/dist/video-js.min.css"
          rel="stylesheet"
        ></link>
        <script src="https://unpkg.com/video.js/dist/video.min.js"></script>
        {/*<video
          id="my-player"
          className="video-js"
          controls
          preload="auto"
          data-setup="{}"
          width={480}
          height={720}
        >
          <source
            src="https://granvan.io/images/2021/20210113_121245.mp4"
            type="video/mp4"
          ></source>
        </video>*/}
        <video
          id="my-player"
          className="video-js"
          controls
          preload="auto"
          data-setup="{}"
          width={480}
        >
          <source
            src="https://granvan.io/images/2022/20220128_133823_1(1).mp4"
            type="video/mp4"
          ></source>
        </video>
        <video
          id="my-player"
          className="video-js"
          controls
          preload="auto"
          data-setup="{}"
          width={480}
        >
          <source
            src="https://granvan.io/images/2022/20220123_130436.mp4"
            type="video/mp4"
          ></source>
        </video>
        {/*} <video
          id="my-player"
          className="video-js"
          controls
          preload="auto"
          data-setup="{}"
          width={480}
          height={720}
        >
          <source
            src="https://granvan.io/images/2022/20220313_122258.mp4"
            type="video/mp4"
          ></source>
        </video>
        <video
          id="my-player"
          className="video-js"
          controls
          preload="auto"
          data-setup="{}"
          width={480}
          height={720}
        >
          <source
            src="https://granvan.io/images/2022/20220315_113057.mp4"
            type="video/mp4"
          ></source>
        </video>*/}
      </div>
    );
  }
}

export default VideoPlayer;

// class VideoPlayer extends React.Component {
//   componentDidMount() {
//     this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
//       console.log("Video.js Ready", this);
//     });
//   }
//   componentWillUnmount() {
//     if (this.player) {
//       this.player.dispose();
//     }
//   }
//   render() {
//     return (
//       <div>
//         <div data-vjs-player>
//           <video
//             ref={(node) => (this.videoNode = node)}
//             className="video-js"
//           ></video>
//         </div>
//       </div>
//     );
//   }
// }
